<template>
<div class="password-wrapper">
    <form>
        <span class="password-title">{{ $t('profile.settings.title') }}</span>
        <span class="password-sub-title">{{ $t('profile.settings.title-desc') }}</span>

        <ValidationObserver>
            <ValidationProvider rules="required" v-slot="{ errors }">
                <div class="form-group">
                    <span class="password-input-title">{{ $t('profile.settings.old-password') }}</span>
                    <el-input
                        type="password"
                        placeholder="********"
                        v-model="oldPassword"
                    ></el-input>
                    <span class="password-error text-danger">{{ errors[0] }}</span>
                </div>
            </ValidationProvider>
            <ValidationProvider rules="required|confirmed:confirmation" v-slot="{ errors }">
                <div class="form-group">
                    <span class="password-input-title">{{ $t('profile.settings.new-password') }}</span>
                    <el-input
                        type="password"
                        placeholder="********"
                        v-model="newPassword"
                    ></el-input>
                    <span class="password-error text-danger">{{ errors[0] }}</span>
                </div>
            </ValidationProvider>
            <ValidationProvider v-slot="{ errors }" rules="required" vid="confirmation">
                <div class="form-group">
                    <span class="password-input-title">{{ $t('profile.settings.password-repeat') }}</span>
                    <el-input
                        type="password"
                        placeholder="********"
                        v-model="repeatPassword"
                    ></el-input>
                    <span class="password-error text-danger">{{ errors[0] }}</span>
                </div>
            </ValidationProvider>
        </ValidationObserver>

        <el-button type="primary" class="password-btn p-0" @click.prevent="changePassword">
            <span class="password-btn-text">{{ $t('profile.settings.save-btn') }}</span>
        </el-button>
    </form>
</div>
</template>
<script>
import {extend, ValidationObserver, ValidationProvider} from "vee-validate";
import * as rules from "vee-validate/dist/rules";
import Vue from "vue";

export default {
    beforeCreate() {
        Object.keys(rules).forEach(rule => {
            extend(rule, rules[rule]);
        });
        extend('required', {
            message: 'Это поле является обязательным.'
        });
        extend('confirmed', {
            message: 'Введенные пароли должны совпадать.'
        });
    },
    components: {
        ValidationObserver,
        ValidationProvider,
        extend
    },
    data(){
        return {
            oldPassword: '',
            newPassword: '',
            repeatPassword: '',
        }
    },
    methods: {
        changePassword () {
            this.loading = true;

            if (this.newPassword !== this.repeatPassword) {
                Vue.toastr({
                    message: 'Ошибка',
                    description: "Ошибка при обновлении пароля" ,
                    type: 'error'
                });

                return
            }
            this.$http.post(window.API_ROOT + '/api/password/update', {
                oldPassword: this.oldPassword,
                password: this.newPassword
            })
                .then(() => {
                    Vue.toastr({
                        message: 'Успешно!',
                        description: "Пароль успешно обновлен",
                        type: 'success'
                    });
                    this.loading = false;
                    this.oldPassword = '';
                    this.newPassword = '';
                    this.repeatPassword = '';
                }).catch(() => {
                    Vue.toastr({
                        message: 'Ошибка',
                        description: "Ошибка при обновлении пароля" ,
                        type: 'error'
                    });
                    this.loading = true;
                    this.oldPassword = '';
                    this.newPassword = '';
                    this.repeatPassword = '';
                });
        },
    },
}
</script>
<style lang="less" scoped>
.password-btn{
  border: none;
  cursor: pointer;
  text-align: center;
  line-height: 40px;
  border-radius: 4px;
  margin-top: 40px;
  width: 100%;
  height: 40px;
  color: white;
  background: #473F95;
}
.password-input-title{
  display: block;
  margin-top: 30px;
  margin-bottom: 9.5px;
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #473F95;

}
.password-title{
  display: block;
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 25px;
  line-height: 26px;
  color: #473F95;
}
.password-sub-title{
  display: block;
  margin-top: 5px;
  font-family: 'Open Sans', 'Roboto', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 18px;

  color: #A39FCA;
}
.password-title-input{
  display: block;
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #473F95;
}
.password-wrapper{
  padding: 65px;
  background: #FFFFFF;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.04), 0 4px 16px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
//   width: 50rem;
  max-width: 543px;
  height: max-content;

}
@media (max-width: 991px) {
    .password-wrapper {
        padding: 20px;
        width: 100%;
    }
    .password-title {
        font-size: 22px;
        line-height: 26px;
    }
    .password-sub-title {
        margin-top: 12px;
        font-size: 12px;
        line-height: 16px;
        font-family: 'Open Sans', sans-serif;
    }
    .password-input-title {
        margin-top: 20px;
    }
    .password-input-title {
        font-weight: 500;
        font-size: 13px;
        line-height: 15px;
    }
}
</style>